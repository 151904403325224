import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HealthService } from '../../services/health.service';
import { CommonModule } from '@angular/common';
import { UserService } from '../../services/user.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { AppService } from '../../services/app.service';
import { WorkspaceService } from '../../services/workspace.service';
import { FormsModule } from '@angular/forms';
import { delay } from 'rxjs';
import { UserStatusComponent } from '../../components/user/user-status/user-status.component';
import { UserInterface, WorkspaceInterface } from 'voxr-shared';
import { TransferAvailabilityComponent } from '../../components/general/transfer-availability/transfer-availability.component';

@Component({
  selector: 'app-sidebar-layout',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, RouterLinkActive, ButtonModule, DropdownModule, TransferAvailabilityComponent, UserStatusComponent],
  templateUrl: './sidebar-layout.component.html',
  styleUrl: './sidebar-layout.component.scss'
})
export class SidebarLayoutComponent {
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private app = inject(AppService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  showSidebar = true;
  user?: UserInterface | null;
  workspaces?: WorkspaceInterface[];
  workspace?: WorkspaceInterface | null;
  workspaceRoles?: string[];
  adminArea = false;
  needOnboarding?: boolean;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.laodUser(user));
    this.workspaceService.workspaces$.pipe(takeUntilDestroyed()).subscribe(workspaces => this.workspaces = workspaces);
    this.workspaceService.workspace$.pipe(takeUntilDestroyed()).subscribe(workspace => this.workspace = workspace);
    this.workspaceService.roles$.pipe(takeUntilDestroyed()).subscribe(roles => this.workspaceRoles = roles);
  }

  ngOnInit() {
    this.adminArea = this.route.snapshot.data['admin'];
  }

  private laodUser(user?: UserInterface | null): void {
    // if(user &&  !user.isApproved)
    //   this.router.navigate(['/register']);
    this.user = user;
  }

  async logout() {
    await this.app.logout();
  }

  async setWorkspace(workspaceId: string) {
    this.router.navigate(['/dashboard']);
    await delay(100);
    this.workspaceService.setWorkspace(workspaceId);
    window.location.reload();
  }


}

