@if(user) {
<a routerLink="/profile" class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer">
  <div
    class="tw-flex tw-items-center tw-justify-center tw-w-10 tw-aspect-square tw-rounded-full  tw-text-white tw-text-2xl tw-bg-contain tw-bg-center"
    [ngClass]="{'tw-bg-purple-700': !user.profile?.['picture']}"
    [ngStyle]="{'background-image': user.profile?.['picture'] ? 'url(' + user.profile?.['picture'] + ')' : undefined}">
    @if(!(user.profile?.['picture'])){ {{(name[0]).toUpperCase()}} }
  </div>
  <span>{{name}}</span>
</a>

}