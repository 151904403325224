import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { UserInterface } from 'voxr-shared';
import { RouterLink } from '@angular/router';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-user-status',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, InputTextModule, RouterLink, InputSwitchModule],
  templateUrl: './user-status.component.html',
  styleUrl: './user-status.component.scss'
})
export class UserStatusComponent {
  private userService = inject(UserService);
  private messageService = inject(MessageService);

  user?: UserInterface | null;
  name: string = ' ';
  loading: boolean = false;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.loadUser(user));
  }

  private loadUser(user?: UserInterface | null) {
    this.user = user;
    if (this.user) {
      this.name = (this.user.profile?.['firstName'] || this.user.profile?.['name'] || this.user.email || '') +
        (this.user.profile?.['lastName'] ? ' ' + this.user.profile['lastName'] : '');
    }

  }

}
