<div class="tw-flex tw-flex-col tw-h-screen">
  <header class="tw-flex tw-items-center tw-justify-between tw-h-16 tw-px-3 surface-main-darker">
    <a class="tw-flex tw-items-center tw-justify-center tw-h-16 tw-me-auto" routerLink="/">
      <img src="assets/images/logo/voxr-logotype.png" alt="Voxr" class="tw-h-10">
    </a>
    @if(user) {
      <app-user-status />
      <p-button icon="pi pi-sign-out" [text]="true" (click)="logout()"></p-button>
      } @else {
      <a routerLink="/login">Login</a>
      }

  </header>
  <div class="tw-flex-grow tw-overflow-y-auto">
    <router-outlet/>
  </div>
</div>

