import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppService } from '../services/app.service';
import { WorkspaceService } from '../services/workspace.service';
import { Auth } from '@angular/fire/auth';
import { from, switchMap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(Auth);
  const workspaceService = inject(WorkspaceService);

  var headers: { [name: string]: string | string[] } = {};

  if(auth.currentUser) {
    return from(auth.currentUser!.getIdToken()).pipe(
      switchMap(idToken => {
        if (idToken) headers['Authorization'] = `Bearer ${idToken}`;
        if (workspaceService.workspaceId) headers['x-voxr-workspace'] = workspaceService.workspaceId;
  
        req = req.clone({ setHeaders: headers });
  
        return next(req);
      }
      ));
  }
  else {
    return next(req);
  }

};
